import { useCallback, useEffect, useState } from 'react'

import EditorJS from '@editorjs/editorjs'
import { createRoot } from 'react-dom/client'

import { unique } from '../../../entries/utils'
import { LabeledInput, ToolHeader } from '../../common'
import Dialog from '../../common/Dialog'
import FaqBlock from '../../common/EditorJsRenderer/blocks/FaqBlock'
import { Add, ArrowDown, ArrowUp } from '../../common/Icons'
import Tooltip from '../../common/Tooltip'
import {
  generateRandomId,
  handleEmbeddedEditorActions,
  renderEditSettingsButton,
  renderHiddenModalButton,
  renderTabs,
} from '../../common/commonUtils'
import { initialiseStyling } from '../../common/commonUtils'
import { AddQuestionTooltip, DeleteQuestionTooltip } from './FaqTooltips'
import { MIN_FAQS, defaultBlocks, handleDeleteQuestion, refreshSteps } from './utils'

const QuestionEditor = ({
  stepIndex,
  isActive,
  isModalOpen,
  numQuestions,
  activeIndexState,
  setActiveIndexState,
  state,
  onDataChange,
  getUpdatedData,
  syncedStateUpdate,
  setSteps,
  config,
}) => {
  // ! Embedded edtior components can't have any internal state management
  const editorJsID = generateRandomId(5)
  const questionState = getUpdatedData()?.questions[stepIndex]

  // Div that holds the embedded editor instance
  const ContentEditorHolder = () => (
    <div
      className="w-100 rounded border position-relative modal-embedded-editor-js-instance"
      id={editorJsID}
    ></div>
  )

  const contentEditorjsInstance = new EditorJS({
    defaultBlock: 'paragraph',
    placeholder: 'Add your content...',
    holder: editorJsID,
    tools: config.tools,
    data: { blocks: questionState?.blocks ?? [] },
    minHeight: 50,
    onReady: () => {
      if (questionState?.blocks) {
        questionState?.blocks?.map((block) => {
          const div = document.querySelector(`[data-id='${block.id}']`)
          // Add custom event listeners to the embedded editor blocks
          handleEmbeddedEditorActions(div, contentEditorjsInstance)
          // Initialise styling for blocks
          initialiseStyling(div, block.tunes)
        })
      }
    },
    onChange: async () => {
      // ! Custom tunes work correctly
      // ! in-built tunes (.e.g alignement) are not working
      const saveData = await contentEditorjsInstance.save()
      const tempBlocks = []

      // Loop through questions and ONLY update the current active question
      for (let index = 0; index < numQuestions; index++) {
        const startingQuestionState = getUpdatedData()?.questions[index]
          ? getUpdatedData()?.questions[index]
          : { blocks: defaultBlocks }

        tempBlocks.push({
          ...startingQuestionState,
          blocks: index === stepIndex ? saveData.blocks : startingQuestionState?.blocks,
        })
      }

      // Save the changes
      onDataChange({
        questions: tempBlocks,
      })

      // Add event listeners to the new blocks
      if (saveData?.blocks) {
        saveData?.blocks?.map((block) => {
          const div = document.querySelector(`[data-id='${block.id}']`)
          // Add custom event listeners to the embedded editor blocks
          handleEmbeddedEditorActions(div, contentEditorjsInstance)
        })
      }
    },
  })

  // Save state updates when changing away from this step
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      const updatedData = getUpdatedData()
      syncedStateUpdate({ ...updatedData })
    }
  }, [isActive, isModalOpen])

  function handleMoveQuestion(direction) {
    const questions = [...getUpdatedData().questions]

    // Calculate target index based on direction
    const targetIndex = direction === 'down' ? stepIndex + 1 : stepIndex - 1

    // Swap current question with target position
    const temp = questions[stepIndex]
    questions[stepIndex] = questions[targetIndex]
    questions[targetIndex] = temp

    // Update state with reordered questions and stay on the same question
    syncedStateUpdate({ ...getUpdatedData(), questions: questions })
    setActiveIndexState({
      activeIndex: targetIndex,
      previousActiveIndex: activeIndexState.activeIndex,
    })
  }

  return (
    <div className={isActive ? 'd-block' : 'd-none'}>
      <div className="row">
        <div className="col-12 z-2 mb-2" style={{ zIndex: 2 }}>
          <ContentEditorHolder />
        </div>
        <div className="col-12 d-flex justify-content-end" style={{ gap: '0.5rem' }}>
          {stepIndex > 0 && (
            <button
              className="d-flex align-items-center btn btn-dark"
              onClick={() => handleMoveQuestion('up')}
            >
              <ArrowUp size="20px" color="white" />
            </button>
          )}
          {stepIndex + 1 < numQuestions && (
            <button
              className="d-flex align-items-center btn btn-dark"
              onClick={() => handleMoveQuestion('down')}
            >
              <ArrowDown size="20px" color="white" />
            </button>
          )}
          <Tooltip
            tooltipTrigger={
              <button
                className="btn btn-danger"
                onClick={() =>
                  handleDeleteQuestion(
                    stepIndex,
                    getUpdatedData,
                    syncedStateUpdate,
                    setActiveIndexState,
                    state,
                    setSteps,
                    QuestionEditor
                  )
                }
                disabled={numQuestions === MIN_FAQS}
              >
                Delete Question
              </button>
            }
            tooltipContent={DeleteQuestionTooltip.content}
            width={200}
          />
        </div>
      </div>
    </div>
  )
}

const RenderedFaqComponent = ({
  onDataChange,
  getUpdatedData,
  data,
  config,
  toolInfo,
  uniqueId,
}) => {
  const [state, setState] = useState(data) // Tool state
  const [show, setShow] = useState(false) // Modal state
  const [steps, setSteps] = useState([])

  // Track form state (active index, previous active index)
  const [activeIndexState, setActiveIndexState] = useState({
    activeIndex: 0,
    previousActiveIndex: undefined,
  })

  // Initialise steps data
  useEffect(() => {
    refreshSteps(data, getUpdatedData, setSteps, QuestionEditor)
  }, [])

  // Save data on modal close
  useEffect(() => {
    if (!show) {
      onDataChange({
        ...getUpdatedData(),
      })
    }
  }, [show])

  // Keep data and state synced
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({ ...item })
      onDataChange({
        ...item,
      })
    },
    [onDataChange]
  )

  return (
    <>
      {/* Render Preview */}
      <FaqBlock state={state} />
      {/* Edit Settings Modal */}
      <Dialog title="FAQs" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} hideToggle />
        <div className="pt-3 border-top">
          {(data?.heading || data?.subheading) && (
            <div className="row">
              {/* Deprecated in favour of using Heading components */}
              {data?.heading && (
                <div className="col-12 col-md-6">
                  <LabeledInput
                    controlled={false}
                    item={state}
                    itemName="heading"
                    label="Heading"
                    updateItem={onDataChange}
                  />
                </div>
              )}
              {/* Deprecated in favour of using Heading components */}
              {data?.subheading && (
                <div className="col-12 col-md-6">
                  <LabeledInput
                    controlled={false}
                    item={state}
                    itemName="subheading"
                    label="Subheading"
                    updateItem={onDataChange}
                  />
                </div>
              )}
              <div className="border-bottom w-100 mb-3 mx-3"></div>
            </div>
          )}
          {renderTabs(
            steps,
            activeIndexState,
            setActiveIndexState,
            undefined,
            <Tooltip
              tooltipTrigger={
                <button
                  className="btn btn-small btn-success d-flex align-items-center ml-2"
                  onClick={() => {
                    const newQuestion = { blocks: defaultBlocks }
                    const updatedQuestions = [...getUpdatedData().questions, newQuestion]

                    syncedStateUpdate({
                      ...getUpdatedData(),
                      questions: updatedQuestions,
                    })
                    setActiveIndexState({
                      activeIndex: updatedQuestions.length - 1,
                      previousActiveIndex: activeIndexState.activeIndex,
                    })
                    refreshSteps(data, getUpdatedData, setSteps, QuestionEditor)
                  }}
                >
                  <Add size={25} />
                </button>
              }
              tooltipContent={AddQuestionTooltip.content}
              width={200}
            />
          )}
          <div className="row pt-3">
            <div className="col-12">
              <div className="form-group mb-0">
                {/* Render Steps */}
                {steps?.map((step, index) => {
                  const StepComponent = step.component

                  return (
                    <StepComponent
                      key={step.name + index}
                      stepIndex={index}
                      isActive={activeIndexState.activeIndex === index}
                      isModalOpen={show}
                      numQuestions={steps?.length}
                      activeIndexState={activeIndexState}
                      setActiveIndexState={setActiveIndexState}
                      state={state}
                      onDataChange={onDataChange}
                      getUpdatedData={getUpdatedData}
                      syncedStateUpdate={syncedStateUpdate}
                      setSteps={setSteps}
                      config={config}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class FaqTool {
  constructor({ data, config, block }) {
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    const defaultData = {
      heading: undefined, // Deprecated
      subheading: undefined, // Deprecated
      questions: [{ blocks: defaultBlocks }],
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.toolInfo = {
      heading: undefined,
      helpText: 'Add FAQ Questions to provide users with additional information.',
      itemName: 'Question',
    }

    this.nodes = {
      holder: null,
    }
  }

  static get toolbox() {
    return {
      title: 'Faq',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" /> </svg>',
    }
  }

  onDataChange(newData) {
    this.data = {
      ...this.data,
      ...newData,
    }
    this.config.save()
  }

  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const getUpdatedData = () => {
      return this.data
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedFaqComponent
        onDataChange={onDataChange}
        getUpdatedData={getUpdatedData}
        data={this.data}
        config={this.config}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  save() {
    return this.data
  }
}

export default FaqTool
