import { PropsWithChildren, useState } from 'react'

import type { API, BlockAPI, BlockTune } from '@editorjs/editorjs'
import { createRoot } from 'react-dom/client'

import {
  LabeledFileInput,
  LabeledInput,
  LabeledSelect,
  bootstrapColours,
  isValidHexColor,
  translateBootstrapColour,
} from '../common'
import Dialog from '../common/Dialog'
import type { BackgroundColorTuneProps } from '../types'

// Create and append options to the select element
function createOption(parent: HTMLSelectElement, value: string, text: string, selected: boolean) {
  const option = document.createElement('option')
  option.value = value
  option.textContent = text
  if (selected) {
    option.selected = true
  }
  parent.appendChild(option)
}

// Button & Dialog for uploading & customising custom background image
const RenderCustomImageInput = ({ children }: PropsWithChildren) => {
  const [showDialog, setShowDialog] = useState(false)
  return (
    <>
      <button
        className="btn btn-sm btn-block btn-outline-info mt-1"
        onClick={() => setShowDialog(true)}
      >
        Customise Image
      </button>
      <Dialog
        title="Custom Background Image"
        show={showDialog}
        closeClickHandler={() => setShowDialog(false)}
        isMaximizable={false}
        customMaxWidth="400px"
        customStyles={{ height: 'fit-content' }}
      >
        {children}
      </Dialog>
    </>
  )
}

export default class BackgroundColorBlockTune implements BlockTune {
  public static isTune = true

  private api: API
  private block: BlockAPI
  private config: {
    save: () => void
    imageUrl: string
  }
  private icon: string
  private label: string
  private data: BackgroundColorTuneProps

  constructor({ api, data, config, block }) {
    this.api = api
    this.block = block
    this.config = config
    this.label = 'Background'
    this.icon = `
      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path d="M766.4 744.3c43.7 0 79.4-36.2 79.4-80.5 0-53.5-79.4-140.8-79.4-140.8S687 610.3 687 663.8c0 44.3 35.7 80.5 79.4 80.5zm-377.1-44.1c7.1 7.1 18.6 7.1 25.6 0l256.1-256c7.1-7.1 7.1-18.6 0-25.6l-256-256c-.6-.6-1.3-1.2-2-1.7l-78.2-78.2a9.11 9.11 0 0 0-12.8 0l-48 48a9.11 9.11 0 0 0 0 12.8l67.2 67.2-207.8 207.9c-7.1 7.1-7.1 18.6 0 25.6l255.9 256zm12.9-448.6l178.9 178.9H223.4l178.8-178.9zM904 816H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c-4.4 0-8 3.6-8 8v-80c0-4.4-3.6-8-8-8z"></path>
      </svg>
    `
    this.data = data
      ? {
          backgroundColor: data.backgroundColor ? data.backgroundColor : 'none',
          customBackgroundType: data.customBackgroundType ? data.customBackgroundType : 'color',
          customBackgroundColor: data.customBackgroundColor
            ? data.customBackgroundColor
            : '#000000',
          customGradient: data.customGradient ? data.customGradient : '',
          customImage: data.customImage
            ? data.customImage
            : {
                url: '',
                layout: 'cover',
                position: 'center',
                alt: '',
              },
        }
      : {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
          customBackgroundType: 'color',
          customGradient: '',
          customImage: {
            url: '',
            layout: 'cover',
            position: 'center',
            alt: '',
          },
        }
  }

  render() {
    // Get the contain div (for applying styles)
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex()
    const currentBlockHolder = this.api.blocks.getBlockByIndex(currentBlockIndex).holder

    // Create the background icon element
    const backgroundIcon = document.createElement('div')
    backgroundIcon.className = 'ce-popover-item__icon'
    backgroundIcon.innerHTML = this.icon

    // Create the background label element
    const backgroundLabel = document.createElement('div')
    backgroundLabel.className = 'ce-popover-item__title'
    backgroundLabel.textContent = this.label

    // Create the background select input element
    const backgroundSelect = document.createElement('select')
    backgroundSelect.id = 'backgroundSelect'
    backgroundSelect.className = 'form-control form-control-sm'
    backgroundSelect.style.background = `var(--${this.data.backgroundColor})`
    // Populate the background select options (none, bootstrap colours, custom)
    createOption(backgroundSelect, 'none', 'none', this.data.backgroundColor === 'none')
    bootstrapColours.forEach((color) => {
      createOption(
        backgroundSelect,
        color.toLowerCase(),
        translateBootstrapColour(color),
        color.toLowerCase() === this.data.backgroundColor
      )
    })
    createOption(backgroundSelect, 'custom', 'custom', this.data.backgroundColor === 'custom')

    // Handle background select change event
    backgroundSelect.addEventListener('change', () => {
      const selectedColor = backgroundSelect.value
      this.data.backgroundColor = selectedColor
      backgroundSelect.style.background = `var(--${selectedColor})`
      // Show/hide the custom background type select
      customBackgroundTypeSelect.style.display = selectedColor === 'custom' ? 'flex' : 'none'
      // Show/hide the custom color container
      customColorContainer.style.display =
        selectedColor === 'custom' && this.data.customBackgroundType === 'color' ? 'flex' : 'none'
      // Show/hide the custom gradient container
      customGradientInput.style.display =
        selectedColor === 'custom' && this.data.customBackgroundType === 'gradient'
          ? 'flex'
          : 'none'
      // Show/hide the custom image container
      customImageInput.style.display =
        selectedColor === 'custom' && this.data.customBackgroundType === 'image' ? 'flex' : 'none'

      if (selectedColor === 'custom') {
        if (this.data.customBackgroundType === 'color') {
          currentBlockHolder.style.background = `${this.data.customBackgroundColor}`
          customColorBlock.style.background = `${this.data.customBackgroundColor}`
        } else if (this.data.customBackgroundType === 'gradient') {
          currentBlockHolder.style.background = `${this.data.customGradient}`
        } else if (this.data.customBackgroundType === 'image') {
          currentBlockHolder.style.background = `url(${this.data.customImage.url})`
          currentBlockHolder.style.backgroundSize = this.data.customImage.layout
          currentBlockHolder.style.backgroundPosition = this.data.customImage.position
          currentBlockHolder.style.backgroundRepeat = 'no-repeat'
        }
      } else {
        currentBlockHolder.style.background = `var(--${selectedColor})`
      }

      this.config.save()
      // Force trigger Block's onChange event
      this.block.dispatchChange()
    })

    // Create the custom background type element
    const customBackgroundTypeSelect = document.createElement('select')
    customBackgroundTypeSelect.id = 'customBackgroundType'
    customBackgroundTypeSelect.className = 'form-control form-control-sm mt-1'
    customBackgroundTypeSelect.style.display =
      this.data.backgroundColor === 'custom' ? 'flex' : 'none'
    // Populate the custom background type select options
    const typeOptions = ['color', 'gradient', 'image']
    typeOptions.forEach((type) => {
      createOption(customBackgroundTypeSelect, type, type, this.data.customBackgroundType === type)
    })

    // Handle custom background type select change event
    customBackgroundTypeSelect.addEventListener('change', () => {
      this.data.customBackgroundType = customBackgroundTypeSelect.value

      // Show/hide relevant containers & update values
      customColorContainer.style.display =
        this.data.backgroundColor === 'custom' && this.data.customBackgroundType === 'color'
          ? 'flex'
          : 'none'
      switch (this.data.customBackgroundType) {
        case 'color':
          customColorContainer.style.display = 'flex'
          customGradientInput.style.display = 'none'
          customImageInput.style.display = 'none'
          currentBlockHolder.style.background = this.data.customBackgroundColor
          break
        case 'gradient':
          customGradientInput.style.display = 'flex'
          customColorContainer.style.display = 'none'
          customImageInput.style.display = 'none'
          currentBlockHolder.style.background = this.data.customGradient
          break
        case 'image':
          customImageInput.style.display = 'flex'
          customColorContainer.style.display = 'none'
          customGradientInput.style.display = 'none'
          currentBlockHolder.style.background = `url(${this.data.customImage.url})`
          currentBlockHolder.style.backgroundSize = this.data.customImage.layout
          currentBlockHolder.style.backgroundPosition = this.data.customImage.position
          currentBlockHolder.style.backgroundRepeat = 'no-repeat'
          break
      }

      this.config.save()
      this.block.dispatchChange()
    })

    // Create the custom color input element
    const customColorInput = document.createElement('input')
    customColorInput.type = 'text'
    customColorInput.value = this.data.customBackgroundColor
    customColorInput.className = 'mr-1 form-control form-control-sm'
    const customColorBlock = document.createElement('div')
    customColorBlock.className = 'ce-popover-item__icon rounded mr-0'
    customColorBlock.style.background = `${this.data.customBackgroundColor}`
    customColorBlock.style.width = '31px'
    customColorBlock.style.height = '31px'
    const customColorContainer = document.createElement('div')
    customColorContainer.className = 'mt-1'
    customColorContainer.style.display =
      this.data.backgroundColor === 'custom' && this.data.customBackgroundType === 'color'
        ? 'flex'
        : 'none'
    customColorContainer.appendChild(customColorInput)
    customColorContainer.appendChild(customColorBlock)

    // Handle custom color input
    customColorInput.addEventListener('input', () => {
      let customColorValue = customColorInput.value

      // Check if the value does not start with #
      if (!customColorInput.value.startsWith('#')) {
        customColorValue = '#' + customColorInput.value // Prepend # to the input value
        customColorInput.value = customColorValue
      }

      if (isValidHexColor(customColorValue)) {
        this.data.customBackgroundColor = customColorValue
        currentBlockHolder.style.background = `${this.data.customBackgroundColor}`
        customColorBlock.style.background = `${this.data.customBackgroundColor}`
        customColorInput.style.background = '#FFFFFF'
        this.config.save()
        // Force trigger Block's onChange event
        this.block.dispatchChange()
      } else {
        customColorInput.style.background = '#FFCCCB'
        customColorInput.style.color = 'var(--dark)'
        currentBlockHolder.style.background = ''
      }
    })

    // Create the custom gradient input element
    const customGradientInput = document.createElement('input')
    customGradientInput.type = 'text'
    customGradientInput.value = this.data.customGradient
    customGradientInput.className = 'form-control form-control-sm mt-1'
    customGradientInput.style.display =
      this.data.backgroundColor === 'custom' && this.data.customBackgroundType === 'gradient'
        ? 'flex'
        : 'none'

    // Handle custom color input
    customGradientInput.addEventListener('input', () => {
      let customGradientValue = customGradientInput.value

      // TODO: Eventually want to add validation here

      this.data.customGradient = customGradientValue
      currentBlockHolder.style.background = `${this.data.customGradient}`
      this.config.save()
      // Force trigger Block's onChange event
      this.block.dispatchChange()
    })

    // Create the custom image input element
    const customImageInput = document.createElement('div')
    customImageInput.style.display =
      this.data.backgroundColor === 'custom' && this.data.customBackgroundType === 'image'
        ? 'flex'
        : 'none'
    const root = createRoot(customImageInput)
    root.render(
      <RenderCustomImageInput>
        <LabeledFileInput
          label="Background Image"
          item={{ Image: this.data.customImage }}
          itemName="Image"
          file={{ url: this.data.customImage.url }}
          accept="image/*"
          imageEndpoint={this.config.imageUrl}
          multiple={false}
          updateItem={({ Image }) => {
            const imageUrl = Array.isArray(Image) ? Image[0].url : Image.url
            this.data.customImage.url = imageUrl
            currentBlockHolder.style.background = `url(${imageUrl})`
            this.config.save()
            this.block.dispatchChange()
          }}
        />
        <LabeledInput
          label="Image Alt Text"
          item={this.data.customImage}
          itemName="alt"
          placeholder="Enter image alt text..."
          customOnChange={(e) => {
            this.data.customImage.alt = e.target.value
            this.config.save()
            this.block.dispatchChange()
          }}
          controlled={false}
        />
        <LabeledSelect
          label="Image Layout"
          item={this.data.customImage}
          itemName="layout"
          options={[
            { value: 'cover', label: 'Cover' },
            { value: 'contain', label: 'Contain' },
          ]}
          customOnChange={(e) => {
            this.data.customImage.layout = e.target.value
            currentBlockHolder.style.backgroundSize = e.target.value
            this.config.save()
            this.block.dispatchChange()
          }}
          controlled={false}
        />
        <LabeledSelect
          label="Image Position"
          item={this.data.customImage}
          itemName="position"
          options={[
            { value: 'center', label: 'Center' },
            { value: 'top', label: 'Top' },
            { value: 'bottom', label: 'Bottom' },
          ]}
          customOnChange={(e) => {
            this.data.customImage.position = e.target.value
            currentBlockHolder.style.backgroundPosition = e.target.value
            this.config.save()
            this.block.dispatchChange()
          }}
          controlled={false}
        />
      </RenderCustomImageInput>
    )

    // Create and append the elements to the wrapper div
    const labelRow = document.createElement('div')
    labelRow.className = 'd-flex flex-row align-items-center mb-1'
    labelRow.appendChild(backgroundIcon)
    labelRow.appendChild(backgroundLabel)

    const backgroundSelectRow = document.createElement('div')
    backgroundSelectRow.appendChild(backgroundSelect)

    const backgroundRow = document.createElement('div')
    backgroundRow.className = 'd-flex flex-column my-1'
    backgroundRow.appendChild(labelRow)
    backgroundRow.appendChild(backgroundSelectRow)
    backgroundRow.appendChild(customBackgroundTypeSelect)
    backgroundRow.appendChild(customColorContainer)
    backgroundRow.appendChild(customGradientInput)
    backgroundRow.appendChild(customImageInput)

    const wrapper = document.createElement('div')
    wrapper.appendChild(backgroundRow)

    return wrapper
  }

  save() {
    return this.data
  }
}
