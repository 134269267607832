import { capitalise } from '../../entries/utils'
import type { LabelTooltipProps } from '../types'
import { Info } from './Icons'
import Tooltip, { TooltipVariants } from './Tooltip'
import { generateRandomId } from './commonUtils'

const RenderTooltip = ({ tooltip }: { tooltip: LabelTooltipProps }) => {
  return (
    <Tooltip
      tooltipTrigger={
        tooltip?.trigger ?? (
          <div
            className="d-inline-flex justify-content-center align-items-center text-primary ml-1"
            style={{ cursor: 'pointer' }}
          >
            <Info
              size="20px"
              stroke={tooltip?.variant ? TooltipVariants[tooltip?.variant] : 'currentColor'}
            />
          </div>
        )
      }
      tooltipContent={tooltip?.content}
      variant={tooltip?.variant}
      width={tooltip?.width}
    />
  )
}

const InputLabel = ({
  label,
  labelClassname,
  htmlFor,
  itemName,
  tooltip,
  containerClassName,
}: {
  label?: string | React.ReactNode
  labelClassname?: string
  htmlFor?: string
  itemName: string
  tooltip?: LabelTooltipProps | LabelTooltipProps[]
  containerClassName?: string
}) => (
  <div className={containerClassName ?? 'd-flex align-items-center mb-2'}>
    <label htmlFor={htmlFor ?? itemName} className={labelClassname ?? 'form-label mb-0'}>
      {label ? label : capitalise(itemName)}
    </label>
    {tooltip &&
      // Allow multiple tooltips to be passed in as an array
      (Array.isArray(tooltip) ? (
        tooltip.map((tooltipData) => {
          return !!tooltipData ? (
            <RenderTooltip key={`tooltip-${generateRandomId(3)}`} tooltip={tooltipData} />
          ) : null
        })
      ) : (
        <RenderTooltip key={`tooltip-${generateRandomId(3)}`} tooltip={tooltip} />
      ))}
  </div>
)

export default InputLabel
