import { unique } from '../../../entries/utils'
import type { ImageSliderProps } from '../../types'

// TODO: Generate based on available brands/promotions
export const starters = {
  empty: {
    slides: [],
  },
}

export const initialState = (data: ImageSliderProps) => {
  return {
    slides: data.slides || [],
    preview: false,
    imageHeight: data.imageHeight,
    imageWidth: data.imageWidth,
    activated: !!data.activated || data.slides.length > 0,
    starter: 'empty',
    useFrame: data?.useFrame || false,
  }
}

export const defaultImageSlide = () => {
  return {
    id: unique(),
    alt: '',
    linkText: '',
    href: '',
  }
}
