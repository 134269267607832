export const frequencyOptions = [
  { label: 'Weekly', value: '52' },
  { label: 'Fortnightly', value: '26' },
  { label: 'Monthly', value: '12' },
  { label: 'Yearly', value: '1' },
]

/**
 * Applies slider rules to the state values before saving.
 * @param {*} sliderState The current state of the slider
 * @returns updated values for min, max, step, and defaultVal
 */
export function handleSliderRules(sliderState) {
  // Ensure min is not less than 0
  const min =
    parseFloat(sliderState.min) < 0 || parseFloat(sliderState.min) == 0
      ? '0'
      : parseFloat(sliderState.min)
  // Ensure max is not less than min
  const max =
    parseFloat(sliderState.max) < parseFloat(sliderState.min)
      ? parseFloat(sliderState.min)
      : parseFloat(sliderState.max)
  // Ensure step is not less than 0.01
  const step = parseFloat(sliderState.step) < 0.01 ? 0.01 : parseFloat(sliderState.step).toFixed(2)
  // Ensure default is not less than min or greater than max
  const defaultVal =
    parseFloat(sliderState.default) < parseFloat(min) ||
    parseFloat(sliderState.default) > parseFloat(max)
      ? (parseFloat(min) + parseFloat(max)) / 2
      : parseFloat(sliderState.default)

  return { min, max, step, defaultVal }
}

/**
 * Converts a color object to a css color value.
 * @param {*} color the color object to handle
 * @returns a css color value
 */
export function handleColorType(color) {
  return color.colorType === 'custom' ? `#${color.customColor}` : `var(--${color.colorType})`
}
