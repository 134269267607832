import * as React from 'react'

// EditorJS Components
import EditorJS from '@editorjs/editorjs'
import Embed from '@editorjs/embed'
import RawTool from '@editorjs/raw'

// Utils
import { handleStretchedImage, initialiseStyling } from './common/commonUtils'
import {
  getAlternatingColumnsConfig,
  getBrandDescriptionConfig,
  getBrandModelsConfig,
  getButtonsConfig,
  getCardsConfig,
  getCarsConfig,
  getColumnsConfig,
  getCustomComponentConfig,
  getFaqConfig,
  getFinanceCalculatorConfig,
  getFormBuilderConfig,
  getFormConfig,
  getHeaderConfig,
  getHorizontalRuleConfig,
  getIconButtonsToolConfig,
  getImageBackgroundConfig,
  getImageBannerConfig,
  getImageConfig,
  getImageSliderConfig,
  getInlineTools,
  getListConfig,
  getLocationsConfig,
  getModelColorsConfig,
  getModelFeaturesConfig,
  getModelVariantsConfig,
  getOffersConfig,
  getParagraphConfig,
  getReviewsConfig,
  getTableConfig,
  getTagConfig,
  getTeamConfig,
  getTunes,
  getVideoConfig,
  getWebsiteBrandsConfig,
} from './editorConfig'
import { handleTemplateRefresh } from './templates/Model/modelRefreshUtils'
import {
  getSearchTemplateEditorTools,
  handleSearchOnChangeEvents,
  handleSearchOnReadyEvents,
} from './templates/Search/searchUtils'
import { fetchData, getTemplate } from './templates/templateUtils'
import { BlockProps, EditorProps } from './types'
import { EditorJsData } from './types/editor.type'

function waitForInput(setInput: React.Dispatch<React.SetStateAction<HTMLInputElement>>) {
  const interval = setInterval(() => {
    const jsonInput = document.getElementById('draft-blocks-json')
    if (jsonInput && jsonInput instanceof HTMLInputElement) {
      setInput(jsonInput)
      clearInterval(interval)
    }
  }, 100) // Check every 100ms
}

export function Editor({ ...props }) {
  const ref = React.useRef<EditorJS>()
  const [isMounted, setIsMounted] = React.useState<boolean>(false)
  const [jsonInput, setInput] = React.useState<HTMLInputElement>(null)

  const {
    isAdmin,
    templateType,
    imageUrl,
    locations,
    brands,
    manufacturerId,
    modelSlug,
    primaryLocationId,
    websiteId,
    ownerManufacturerId,
    searchFilters,
  } = props

  // Set up template data object
  const templateData = {
    website_id: websiteId ?? undefined,
    type:
      // Handle existing model/manufacturer templates with default type
      templateType === 'default'
        ? modelSlug
          ? 'model'
          : manufacturerId
            ? 'manufacturer'
            : templateType // default
        : // Use template type if it is not default
          templateType,
    manufacturer: manufacturerId !== '' ? manufacturerId : (ownerManufacturerId ?? undefined),
    model: modelSlug !== '' ? modelSlug : undefined,
    searchFilters: searchFilters ?? undefined,
  }

  const initializeEditor = React.useCallback(async () => {
    if (!ref.current && jsonInput) {
      let data: EditorJsData = undefined
      if (jsonInput.value) {
        data = JSON.parse(jsonInput.value)

        // Only runs if it is a template page and data is empty
        if (
          templateData.type !== 'default' &&
          (Object.keys(data).length < 1 || data?.blocks?.length < 1)
        ) {
          const template = await getTemplate(templateData, brands)
          data.blocks = template
          jsonInput.value = JSON.stringify(data)
        }
      }

      // The ImageBackgroundTool is deprecated as per 28/03/24, we only allow for existing instances
      const hasImageBackground =
        data?.blocks?.some((block) => block?.type === 'imageBackground') ?? false

      // @ts-ignore Configuration does exist on editor - MM 09/12/2024
      const editor: EditorProps = new EditorJS({
        holder: 'editorjs',
        placeholder: 'Type your content here!',
        tools:
          templateData.type === 'search'
            ? getSearchTemplateEditorTools(
                saved,
                imageUrl,
                brands,
                locations,
                templateData,
                isAdmin,
                searchFilters
              )
            : {
                header: getHeaderConfig(),
                paragraph: getParagraphConfig(),
                image: getImageConfig(imageUrl),
                list: getListConfig(),
                table: getTableConfig(),
                horizontalRule: getHorizontalRuleConfig(saved),
                embed: Embed,
                cars: getCarsConfig(searchFilters, saved),
                offers: getOffersConfig(saved),
                form: getFormConfig(saved),
                formBuilder: getFormBuilderConfig(locations, imageUrl),
                buttonsTool: getButtonsConfig(saved),
                tag: getTagConfig(saved),
                cardsTool: getCardsConfig(imageUrl, saved),
                faq: getFaqConfig(imageUrl, locations, searchFilters, saved, true),
                locations: getLocationsConfig(locations, primaryLocationId, saved),
                reviews: getReviewsConfig(locations, saved),
                team: getTeamConfig(saved),
                raw: RawTool,
                columns: getColumnsConfig(imageUrl, locations, searchFilters, saved, true),
                alternatingColumns: getAlternatingColumnsConfig(
                  imageUrl,
                  locations,
                  searchFilters,
                  saved,
                  true
                ),
                imageBanner: getImageBannerConfig(brands, templateData, imageUrl, saved),
                imageSlider: getImageSliderConfig(imageUrl, saved),
                websiteBrands: getWebsiteBrandsConfig(brands, imageUrl, saved),
                brandModels: getBrandModelsConfig(brands, ownerManufacturerId, saved),
                modelVariants: getModelVariantsConfig(brands, ownerManufacturerId, saved),
                modelColors: getModelColorsConfig(brands, ownerManufacturerId, saved),
                iconButtonsTool: getIconButtonsToolConfig(saved),
                brandDescription: getBrandDescriptionConfig(brands, imageUrl, saved),
                modelFeatures: getModelFeaturesConfig(imageUrl, saved),
                financeCalculator: getFinanceCalculatorConfig(saved),
                video: getVideoConfig(saved),
                customComponent: getCustomComponentConfig(templateData, isAdmin, imageUrl, saved),
                ...(hasImageBackground && {
                  imageBackground: getImageBackgroundConfig(imageUrl, saved),
                }),
                // Custom inline tools
                ...getInlineTools(),
                // Tunes
                ...getTunes(saved, imageUrl),
              },
        autofocus: false,
        /** To make the Editor have more screen-space, close the sidebar automatically */
        onReady: async () => {
          const sidebar = document.getElementById('sidebar')
          if (sidebar && !sidebar.classList.contains('collapsed')) {
            sidebar.classList.toggle('collapsed')
          }

          editor.configuration.data.blocks.map((block: BlockProps) => {
            const div = document.querySelector(`[data-id='${block.id}']`) as HTMLElement
            return initialiseStyling(div, block.tunes, block.type)
          })

          // Add onReady event listeners for Search pages
          if (templateData.type === 'search') {
            handleSearchOnReadyEvents()
          } else if (templateData.type === 'model') {
            // Fetch the updated model data
            const updatedModelData = await fetchData('models', templateData.model).then((data) => {
              return data
            })

            // Add template refresh logic for model pages
            if (updatedModelData) {
              handleTemplateRefresh(updatedModelData, editor)
            }
          }

          // Force editor to save its state data on ready
          // This is to ensure templates are loaded correctly
          saved()
        },
        onChange: (api, event) => {
          // Add onChange event listeners for Search pages
          if (templateData.type === 'search') {
            handleSearchOnChangeEvents(api, event, searchFilters)
          }

          // Custom handling for image block formatting
          // Check if event.detail.index is not undefined or null, since 0 is falsy
          // @ts-ignore event.detail does exist\
          if (event?.detail?.index !== undefined && event?.detail?.index !== null) {
            // @ts-ignore
            const block = api.blocks.getBlockByIndex(event.detail.index)
            if (block.name === 'image') {
              handleStretchedImage(block)
            }
          }
          saved()
        },
        data: data,
      })

      // @ts-ignore Need to investigate this - MM 09/12/2024
      async function saved() {
        // Wait for the editor to be ready before allowing save function to be called
        await editor.isReady
        editor
          .save()
          .then((savedData: EditorJsData) => {
            const event = new CustomEvent('editorSave', { detail: JSON.stringify(savedData) })
            window.dispatchEvent(event)
            jsonInput.value = JSON.stringify(savedData)
          })
          .catch((error) => {
            console.error('Saving error', error)
          })
      }
    }
  }, [jsonInput])

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMounted(true)
      waitForInput(setInput)
    }
  }, [])

  React.useEffect(() => {
    if (isMounted) {
      initializeEditor()

      return () => {
        ref.current?.destroy()
        ref.current = undefined
      }
    }
  }, [isMounted, initializeEditor])

  if (!isMounted) {
    return null
  }

  return <div id="editorjs" />
}
