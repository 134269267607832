import type { BlockAPI, BlockTune } from '@editorjs/editorjs'

import type { ContainerTuneProps } from '../types'

const elementsWithNoHardcodedContainer = ['header', 'paragraph', 'image']

export default class ContainerTune implements BlockTune {
  public static isTune = true

  private block: BlockAPI
  private config: {
    save: () => void
  }
  private data: ContainerTuneProps
  private ceBlockContent: HTMLElement
  private containerWrapper: HTMLElement
  private rowWrapper: HTMLElement
  private colWrapper: HTMLElement

  constructor({ data, config, block }) {
    this.block = block
    this.config = config
    this.data = data || { contain: false }
    this.ceBlockContent = null
    this.containerWrapper = null
    this.rowWrapper = null
    this.colWrapper = null
  }

  render() {
    const wrapper = document.createElement('div')

    const toggleButton = document.createElement('div')
    toggleButton.className = 'ce-popover-item w-100 mb-1'

    toggleButton.innerHTML = `
        <div class="d-flex flex-row align-items-center">
          <div class="ce-popover-item__icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25" />
            </svg>
          </div>
          <div class="ce-popover-item__title">Contain</div>
        </div>
      `

    // Set the initial state of the toggle button
    if (this.data.contain) {
      toggleButton.classList.add('ce-popover-item--active')
    }
    // Handle toggling data and UI
    toggleButton.addEventListener('click', () => {
      this.data.contain = !this.data.contain
      toggleButton.classList.toggle('ce-popover-item--active')
      // Custom handling for image block formatting
      if ((this.block.name === 'image' || this.block.name === 'imageSlider') && this.data.contain) {
        this.ceBlockContent.classList.add('contained-content')
        this.containerWrapper.classList.add('container')
        this.rowWrapper.classList.add('row')
        this.colWrapper.classList.add('col-12')
        this.colWrapper.classList.add('col-lg-10')
        this.colWrapper.classList.add('col-xl-9')
      } else if (
        (this.block.name === 'image' &&
          !this.data.contain &&
          this.ceBlockContent.children[0].classList.contains('image-tool--stretched')) ||
        (this.block.name === 'imageSlider' && !this.data.contain)
      ) {
        this.ceBlockContent.classList.remove('contained-content')
        this.containerWrapper.classList.remove('container')
        this.rowWrapper.classList.remove('row')
        this.colWrapper.classList.remove('col-12')
        this.colWrapper.classList.remove('col-lg-10')
        this.colWrapper.classList.remove('col-xl-9')
      } else {
        this.colWrapper.classList.toggle('col-lg-10')
        this.colWrapper.classList.toggle('col-xl-9')
      }
      this.config.save()
      // Force trigger Block's onChange event
      this.block.dispatchChange()
    })

    wrapper.appendChild(toggleButton)

    return wrapper
  }

  wrap(blockContent: HTMLElement) {
    const containerWrapper = document.createElement('div')
    containerWrapper.className = 'container'
    const rowWrapper = document.createElement('div')
    rowWrapper.className = 'row justify-content-center'
    const colWrapper = document.createElement('div')
    colWrapper.className = this.data.contain ? 'col-12 col-lg-10 col-xl-9' : 'col-12'

    if (!elementsWithNoHardcodedContainer.includes(this.block.name)) {
      colWrapper.classList.add('px-0')
    }

    // Combine block content with wrappers
    colWrapper.appendChild(blockContent)
    rowWrapper.appendChild(colWrapper)
    containerWrapper.appendChild(rowWrapper)
    this.containerWrapper = containerWrapper
    this.rowWrapper = rowWrapper
    this.colWrapper = colWrapper

    const ceBlockContent = colWrapper.querySelector('.ce-block__content')
    if (ceBlockContent && ceBlockContent instanceof HTMLElement) {
      this.ceBlockContent = ceBlockContent
      ceBlockContent.classList.add('contained-content')
    }

    return containerWrapper
  }

  save() {
    return this.data
  }
}
