import { generateRandomId } from '../../common/commonUtils'

// Constants
export const MIN_ROWS = 1
export const COL_KEYS = ['textCol', 'imgCol']
export const DEFAULT_IMAGE_CUSTOMISATIONS = {
  topPadding: 0,
  bottomPadding: 0,
  leftPadding: 0,
  rightPadding: 0,
  backgroundColor: {
    colorType: undefined,
    customColor: 'ffffff',
  },
  imageLayout: 'contain',
}
export const DEFAULT_TEXT_CUSTOMISATIONS = {
  topPadding: 2,
  bottomPadding: 2,
  leftPadding: 2,
  rightPadding: 2,
  backgroundColor: {
    colorType: undefined,
    customColor: 'ffffff',
  },
  textColor: {
    colorType: 'inherit',
    customColor: '000000',
  },
  verticalAlignment: 'center',
}

// Default text block values
export const defaultTextBlocks = [
  {
    id: generateRandomId(10),
    type: 'header',
    data: {
      text: 'Heading...',
      level: 2,
    },
    tunes: {
      alignment: {
        alignment: 'left',
      },
      classname: {
        className: '',
      },
    },
  },
  {
    id: generateRandomId(10),
    type: 'paragraph',
    data: {
      text: 'Text block...',
    },
    tunes: {
      alignment: {
        alignment: 'left',
      },
      classname: {
        className: '',
      },
    },
  },
]

export const defaultImageBlocks = []

export const getDefaultRowData = () => ({
  imgCol: {
    blocks: [...defaultImageBlocks],
    customisations: {
      ...DEFAULT_IMAGE_CUSTOMISATIONS,
      mobile: {
        active: false,
        ...DEFAULT_IMAGE_CUSTOMISATIONS,
      },
      tablet: {
        active: false,
        ...DEFAULT_IMAGE_CUSTOMISATIONS,
      },
    },
  },
  textCol: {
    blocks: [...defaultTextBlocks],
    customisations: {
      ...DEFAULT_TEXT_CUSTOMISATIONS,
      mobile: {
        active: false,
        ...DEFAULT_TEXT_CUSTOMISATIONS,
      },
      tablet: {
        active: false,
        ...DEFAULT_TEXT_CUSTOMISATIONS,
      },
    },
  },
})

/**
 * Updates the steps of the editor to account for the number of active columns.
 * @param {*} data The current data object.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} RowEditor The editor component for the rows.
 */
export function refreshSteps(data, getUpdatedData, setSteps, RowEditor) {
  const tempSteps = []
  const rowNumber = data !== undefined ? getUpdatedData().columns?.length : 0

  // Add each row as a step
  for (let index = 0; index < rowNumber; index++) {
    tempSteps.push({ name: `Row ${index + 1}`, component: RowEditor })
  }

  setSteps(tempSteps)
}

/**
 * Handles the addition of a new row to the tool.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} syncedStateUpdate Function to update the state of the editor.
 * @param {*} activeIndexState The current active index state.
 * @param {*} setActiveIndexState Function to set the active index of the editor.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} RowEditor The editor component for the rows.
 */
export function handleAddRow(
  getUpdatedData,
  syncedStateUpdate,
  activeIndexState,
  setActiveIndexState,
  setSteps,
  RowEditor
) {
  const updatedRows = getUpdatedData().columns
  updatedRows.push(getDefaultRowData())

  syncedStateUpdate({
    ...getUpdatedData(),
    columns: updatedRows,
  })
  setActiveIndexState({
    activeIndex: updatedRows?.length - 1,
    previousActiveIndex: activeIndexState.activeIndex,
  })

  refreshSteps(getUpdatedData(), getUpdatedData, setSteps, RowEditor)
}

/**
 * Handles the deletion of a row from the tool.
 * @param {*} index The index of the row to delete.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} syncedStateUpdate Function to update the state of the editor.
 * @param {*} setActiveIndexState Function to set the active index of the editor.
 * @param {*} data The current data object.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} RowEditor The editor component for the rows.
 */
export function handleDeleteRow(
  index,
  getUpdatedData,
  syncedStateUpdate,
  setActiveIndexState,
  data,
  setSteps,
  RowEditor
) {
  // Confirm delete action
  if (
    window.confirm(
      'Are you sure you want to delete this row? This action will delete all data within both columns in this row.'
    )
  ) {
    const updatedRows = []
    getUpdatedData().columns.map((row, rowIndex) => {
      if (rowIndex !== index) {
        updatedRows.push(row)
      }
    })

    syncedStateUpdate({
      ...getUpdatedData(),
      columns: updatedRows,
    })
    setActiveIndexState({
      activeIndex: index === 0 ? 0 : index - 1,
      previousActiveIndex: undefined,
    })

    refreshSteps(data, getUpdatedData, setSteps, RowEditor)
  }
}

export function handleUpdateColumn(newData, stepIndex, colKey, getUpdatedData, onDataChange) {
  const tempRowsData = []

  // Loop through all rows, only updating the active row
  for (let rowIndex = 0; rowIndex < getUpdatedData()?.columns?.length; rowIndex++) {
    // Only update the active row
    if (rowIndex === stepIndex) {
      // Start with current state data
      const tempColsData = {
        textCol: getUpdatedData()?.columns[rowIndex]['textCol'] ?? { blocks: [] },
        imgCol: getUpdatedData()?.columns[rowIndex]['imgCol'] ?? { blocks: [] },
      }

      // Loop through both columns in the active row, only updating the active column
      for (let colIndex = 0; colIndex < 2; colIndex++) {
        if (colIndex === COL_KEYS.indexOf(colKey)) {
          tempColsData[COL_KEYS[colIndex]] = {
            ...getUpdatedData()?.columns[rowIndex][colKey],
            ...newData,
          }
        }
      }

      tempRowsData.push(tempColsData)
    } else {
      // Maintain current state for all other rows
      tempRowsData.push(getUpdatedData()?.columns[rowIndex])
    }
  }

  // Save the changes
  onDataChange({
    ...getUpdatedData(),
    columns: tempRowsData,
  })
}
