import { type CSSProperties, type PropsWithChildren, useState } from 'react'

import { parseQuestions } from '../../../tools/FaqTool/utils'
import type { BlockDataProps } from '../../../types'
import { Add, Minus } from '../../Icons'
import EditorJsRenderer from '../EditorJsRenderer'

const HolderStyles: CSSProperties = {
  position: 'relative',
  marginTop: '1.75rem',
}

const CollapseStyles: CSSProperties = {
  display: 'flex',
  borderBottom: '1px solid #c3c3c3',
  marginBottom: '1rem',
  position: 'relative',
  padding: '0.25rem 0 1rem 0',
  alignItems: 'center',
  cursor: 'pointer',
}

const TitleStyles: CSSProperties = {
  maxWidth: '85%',
  marginBottom: '0',
}

const IconStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 'auto',
  background: '#f4f5f6',
  padding: ' 0.75rem',
  height: '2.5rem',
  width: '2.5rem',
  borderRadius: '50%',
  overflow: 'hidden',
}

const FaqHolder = ({ title, children }: { title: string } & PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div style={{ ...HolderStyles }}>
      <div onClick={() => setIsOpen(!isOpen)} style={{ ...CollapseStyles }}>
        <h5 style={{ ...TitleStyles }}>{title}</h5>
        <div style={{ ...IconStyles }}>
          {!isOpen ? (
            <Add size={20} color="var(--primary)" />
          ) : (
            <Minus size={20} color="var(--primary)" />
          )}
        </div>
      </div>
      <div className={isOpen ? 'd-block' : 'd-none'}>{children}</div>
    </div>
  )
}

const FaqBlock = ({ state, searchFilters }: { state: BlockDataProps; searchFilters: string }) => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 m-auto">
        {/* Heading & Subheading are deprecated, this handles existing instances */}
        {state?.heading && <h2 className="text-center">{state.heading}</h2>}
        {state?.subheading && <p className="text-center">{state.subheading}</p>}
        {parseQuestions(state?.questions)?.map((question, index) => (
          <FaqHolder key={`question-${index}`} title={question?.heading?.data?.text}>
            <EditorJsRenderer
              key={`block-${index}`}
              data={{ blocks: question.textBlocks }}
              searchFilters={searchFilters}
            />
          </FaqHolder>
        ))}
      </div>
    </div>
  </div>
)

export default FaqBlock
