import {
  capitalize,
  filterUniqueItems,
  generateRandomId,
  humanize,
  parameterize,
} from '../../common/commonUtils'
import { BrandsDataProps, ModelProps } from '../../types'
import { getCarsViewerFilters, getErrorMessage, getFormattedAddressData } from '../templateUtils'
import {
  handleBanner,
  handleColors,
  handleDisclaimer,
  handleFeatures,
  handleLocationSelectData,
  handleVariants,
} from './modelUtils'

// * Eventually want to convert these object types to specific types
function handleComingSoonTemplate(
  model: ModelProps,
  bannerBlock: object[],
  formattedAddressDetails: { uniqueCities?: string; state?: string },
  variantsBlock: object[],
  colorsBlock: object[],
  featuresBlocks: object[],
  locationSelectData: object,
  disclaimerBlock: object
) {
  return [
    ...bannerBlock,
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `${model.status ? humanize(model.status) : ''} ${capitalize(
          model.manufacturer_name
        )} ${model.name}`,
        level: 2,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 5, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'tag',
      data: {
        tags: [
          {
            id: generateRandomId(10),
            text: humanize(model.status),
            backgroundColor: 'primary',
            customBackgroundColor: '#000000',
            textColor: 'light',
            customTextColor: '#FFFFFF',
          },
        ],
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 3,
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: model.short_description, level: 4 },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `${formattedAddressDetails.uniqueCities} ${formattedAddressDetails.state}`,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: model.description,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    ...variantsBlock,
    ...colorsBlock,
    ...featuresBlocks,
    {
      id: generateRandomId(10),
      type: 'formBuilder',
      data: {
        type: 'single',
        category: 'New Vehicle Enquiry',
        heading: 'Register your interest',
        description: '',
        formSteps: [
          {
            key: 0,
            heading: 'Register your interest',
            description: '',
            id: generateRandomId(10),
          },
        ],
        fields: [
          {
            id: generateRandomId(10),
            name: 'name',
            type: 'text',
            label: 'Full Name',
            placeholder: 'Full Name',
            stepKey: 0,
          },
          {
            id: generateRandomId(10),
            name: 'phone',
            type: 'phone',
            label: 'Mobile Number',
            placeholder: 'Mobile',
            stepKey: 0,
          },
          {
            id: generateRandomId(10),
            name: 'email',
            type: 'email',
            label: 'Email Address',
            placeholder: 'Email',
            stepKey: 0,
          },
          {
            id: generateRandomId(10),
            type: 'message',
            name: 'message',
            label: 'Message',
            placeholder: 'Message',
            options: [],
            specialValue: '',
            stepKey: 0,
          },
          locationSelectData,
          {
            id: generateRandomId(10),
            type: 'check',
            name: 'opt_in',
            label: 'Yes, I would like to subscribe to receive latest offers & product updates.',
            placeholder: 'Enter your details...',
            required: false,
            options: [],
            specialValue: '',
            stepKey: 0,
            defaultChecked: true,
          },
          {
            id: generateRandomId(10),
            type: 'hidden',
            name: 'model',
            label: 'Example',
            placeholder: 'Enter your details..',
            options: [],
            specialValue: model.name,
            stepKey: 0,
          },
          {
            id: generateRandomId(10),
            type: 'hidden',
            name: 'make',
            label: 'Example',
            placeholder: 'Enter your details..',
            options: [],
            specialValue: model.manufacturer_name,
            stepKey: 0,
          },
          {
            id: generateRandomId(10),
            type: 'hidden',
            name: 'manufacturer_slug',
            label: 'Example',
            placeholder: 'Enter your details..',
            options: [],
            specialValue: parameterize(model.manufacturer_name),
            stepKey: 0,
          },
        ],
      },
      tunes: {
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        margin: { spacingTop: 5, spacingBottom: 5 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    disclaimerBlock,
  ]
}

/**
 * Generates a template for a model page based on website details and model information.
 * @param websiteDetails - Details of the website.
 * @param model - Information about the model.
 * @returns An array of blocks representing the model page template.
 */
export function modelTemplate(
  websiteDetails: BrandsDataProps,
  model: ModelProps,
  searchFilters: string
) {
  // Error Handling
  if (model === undefined) {
    return getErrorMessage()
  }

  const isOEMTemplate = !websiteDetails

  // Filter locations & addresses
  const uniqueLocations = !isOEMTemplate
    ? (filterUniqueItems(websiteDetails.locations, 'id') ?? undefined)
    : undefined
  const uniqueAddresses = uniqueLocations
    ? filterUniqueItems(uniqueLocations, 'address.id')
    : undefined

  // Template specific logic
  const bannerBlock = handleBanner(model)
  const filters = getCarsViewerFilters(searchFilters)
  const formattedAddressDetails = isOEMTemplate
    ? { uniqueCities: '{website.city}', state: '{website.state}' }
    : uniqueAddresses
      ? getFormattedAddressData(uniqueAddresses)
      : { uniqueCities: undefined, state: undefined }
  const variantsBlock = handleVariants(
    model.model_variants,
    model.id,
    isOEMTemplate ? model.manufacturer_id : websiteDetails.manufacturer_id
  )
  const colorsBlock = handleColors(
    model.colours,
    model.id,
    isOEMTemplate ? model.manufacturer_id : websiteDetails.manufacturer_id
  )
  const featuresBlocks = handleFeatures(model, model.features)
  const locationSelectData = handleLocationSelectData(uniqueLocations, isOEMTemplate)
  const disclaimerBlock = handleDisclaimer(model.disclaimer)

  // If the model is coming soon, use coming soon template
  // ! Need to add OEM handling for this as well
  if (model.status === 'coming_soon') {
    return handleComingSoonTemplate(
      model,
      bannerBlock,
      formattedAddressDetails,
      variantsBlock,
      colorsBlock,
      featuresBlocks,
      locationSelectData,
      disclaimerBlock
    )
  }

  // Use the default template
  return [
    ...bannerBlock,
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `${model.status ? humanize(model.status) : ''} ${capitalize(
          model.manufacturer_name
        )} ${model.name}`,
        level: 2,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 5, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: model.short_description, level: 4 },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `${formattedAddressDetails.uniqueCities} ${formattedAddressDetails.state}`,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'secondary', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: model.description,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'iconButtonsTool',
      data: {
        buttons: [
          {
            id: generateRandomId(10),
            icon: 'Quote',
            text: 'Get a Quote',
            href: `/models/${parameterize(model.name)}/order-now`,
          },
          {
            id: generateRandomId(10),
            icon: 'Test Drive',
            text: 'Book Test Drive',
            href: `/book-test-drive?make=${model.manufacturer_name}&model_id=${model.id}&model=${model.name}`,
          },
          {
            id: generateRandomId(10),
            icon: 'Finance',
            text: 'Finance',
            href: '/finance-pre-approval',
          },
        ],
      },
      tunes: {
        alignment: { alignment: 'left' },
        container: { contain: false },
        margin: { spacingTop: 0, spacingBottom: 0 },
        classname: { className: '' },
      },
    },
    ...variantsBlock,
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `In Stock at ${isOEMTemplate ? '{website.name}' : websiteDetails.website_name}`,
        level: 2,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: {
          backgroundColor: 'light',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: { spacingTop: 5, spacingBottom: 0 },
        padding: { paddingTop: 5, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'cars',
      data: {
        searchState: {
          configure: {
            filters: filters,
          },
          menu: { make: model.manufacturer_name, model: model.name },
          page: 1,
          refinementList: {
            model: [model.name],
            make: [model.manufacturer_name],
            car_type: ['new', 'demo'],
          },
        },
        multiSelect: true,
        dateFilter: null,
        hideButton: false,
        hideSearch: true,
        heading: undefined,
        background: 'none',
        buttonColor: 'primary',
      },
      tunes: {
        backgroundColor: { backgroundColor: 'light', customBackgroundColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 5 },
        padding: { paddingTop: 0, paddingBottom: 5 },
        classname: { className: '' },
      },
    },
    ...colorsBlock,
    {
      id: generateRandomId(10),
      type: 'offers',
      data: {
        searchState: {
          menu: { 'manufacturer.name': model.manufacturer_name, 'model.name': model.name },
          page: 1,
          configure: {
            filters: '',
          },
        },
        dateFilter: null,
      },
    },
    ...featuresBlocks,
    {
      id: generateRandomId(10),
      type: 'formBuilder',
      data: {
        heading: 'Make an enquiry',
        category: 'New Vehicle Enquiry',
        fields: [
          {
            id: generateRandomId(10),
            name: 'name',
            type: 'text',
            label: 'Full Name',
            placeholder: 'Full Name',
          },
          {
            id: generateRandomId(10),
            name: 'phone',
            type: 'phone',
            label: 'Mobile Number',
            placeholder: 'Mobile',
          },
          {
            id: generateRandomId(10),
            name: 'email',
            type: 'email',
            label: 'Email Address',
            placeholder: 'Email',
          },
          {
            id: generateRandomId(10),
            type: 'message',
            name: 'message',
            label: 'Message',
            placeholder: 'Message',
            options: [],
            specialValue: '',
          },
          locationSelectData,
          {
            id: generateRandomId(10),
            type: 'hidden',
            name: 'model',
            label: 'Example',
            placeholder: 'Enter your details..',
            options: [],
            specialValue: model.name,
          },
          {
            id: generateRandomId(10),
            type: 'hidden',
            name: 'make',
            label: 'Example',
            placeholder: 'Enter your details..',
            options: [],
            specialValue: model.manufacturer_name,
          },
          {
            id: generateRandomId(10),
            type: 'hidden',
            name: 'manufacturer_slug',
            label: 'Example',
            placeholder: 'Enter your details..',
            options: [],
            specialValue: parameterize(model.manufacturer_name),
          },
        ],
      },
      tunes: {
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        margin: { spacingTop: 5, spacingBottom: 5 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    disclaimerBlock,
  ]
}
